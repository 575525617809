import React, { useEffect, useRef } from 'react';
import { useTheme } from '../context/ThemeContext';

const MatrixBackground = () => {
  const canvasRef = useRef(null);
  const { isDarkMode } = useTheme();

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const setCanvasSize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };

    setCanvasSize();
    window.addEventListener('resize', setCanvasSize);

    // Matrix rain effect
    const fontSize = 20;
    const columns = Math.floor(window.innerWidth / fontSize);
    const rainDrops = Array(columns).fill(0);
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789@#$%^&*";
    let lastTime = 0;
    const frameRate = 30; // FPS ni kamaytirdik
    const interval = 1000 / frameRate;

    const drawMatrix = (currentTime) => {
      if (currentTime - lastTime < interval) {
        return;
      }
      lastTime = currentTime;

      ctx.fillStyle = isDarkMode ? 'rgba(0, 0, 0, 0.1)' : 'rgba(255, 255, 255, 0.05)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      ctx.font = `${fontSize}px monospace`;
      ctx.textAlign = 'center';

      for (let i = 0; i < rainDrops.length; i++) {
        const char = characters[Math.floor(Math.random() * characters.length)];
        const x = i * fontSize;
        const y = rainDrops[i] * fontSize;

        const gradient = ctx.createLinearGradient(x, y - fontSize, x, y);
        if (isDarkMode) {
          gradient.addColorStop(0, 'rgba(0, 255, 0, 0)');
          gradient.addColorStop(0.8, 'rgba(0, 255, 0, 0.5)');
          gradient.addColorStop(1, 'rgba(0, 255, 0, 1)');
        } else {
          gradient.addColorStop(0, 'rgba(0, 150, 0, 0)');
          gradient.addColorStop(0.8, 'rgba(0, 150, 0, 0.7)');
          gradient.addColorStop(1, 'rgba(0, 150, 0, 1)');
        }
        
        ctx.fillStyle = gradient;
        ctx.fillText(char, x, y);

        if (y > canvas.height && Math.random() > 0.99) {
          rainDrops[i] = 0;
        } else {
          rainDrops[i] += 0.3; // Tezlikni oshirdik
        }
      }
    };

    // Light mode uchun particles
    const particles = Array(50).fill().map(() => ({
      x: Math.random() * canvas.width,
      y: Math.random() * canvas.height,
      radius: Math.random() * 2,
      speedX: (Math.random() - 0.5) * 0.5, // Zarrachalar tezligini kamaytirdik
      speedY: (Math.random() - 0.5) * 0.5, // Zarrachalar tezligini kamaytirdik
      life: Math.random() * 100
    }));

    const drawParticles = () => {
      ctx.fillStyle = 'rgba(255, 255, 255, 0.1)';
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      particles.forEach(particle => {
        particle.x += particle.speedX;
        particle.y += particle.speedY;
        particle.life -= 0.05; // Hayot kamayish tezligini kamaytirdik

        // Zarrachani qayta tiklash
        if (particle.life <= 0) {
          particle.x = Math.random() * canvas.width;
          particle.y = Math.random() * canvas.height;
          particle.life = 100;
        }

        // Chegaralardan qaytish
        if (particle.x < 0 || particle.x > canvas.width) particle.speedX *= -1;
        if (particle.y < 0 || particle.y > canvas.height) particle.speedY *= -1;

        // Zarrachani chizish
        ctx.beginPath();
        ctx.arc(particle.x, particle.y, particle.radius, 0, Math.PI * 2);
        ctx.fillStyle = `rgba(0, 204, 126, ${particle.life / 100})`;
        ctx.fill();

        // Yaqin zarrachalarni chiziq bilan bog'lash
        particles.forEach(otherParticle => {
          const dx = otherParticle.x - particle.x;
          const dy = otherParticle.y - particle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);

          if (distance < 100) {
            ctx.beginPath();
            ctx.strokeStyle = `rgba(0, 204, 126, ${(100 - distance) / 500})`;
            ctx.lineWidth = 0.5;
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            ctx.stroke();
          }
        });
      });
    };

    const animate = (currentTime) => {
      if (isDarkMode) {
        drawMatrix(currentTime);
      } else {
        drawParticles();
      }
      animationFrameId = requestAnimationFrame(animate);
    };

    animate();

    return () => {
      window.removeEventListener('resize', setCanvasSize);
      cancelAnimationFrame(animationFrameId);
    };
  }, [isDarkMode]);

  return (
    <canvas
      ref={canvasRef}
      style={{
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 0,
        opacity: isDarkMode ? 1 : 0.8,
        mixBlendMode: isDarkMode ? 'screen' : 'multiply',
        backgroundColor: 'transparent',
        pointerEvents: 'none'
      }}
    />
  );
};

export default MatrixBackground;
