import React, { useState, useRef } from 'react';
import { motion, useInView } from 'framer-motion';
import { FaGithub, FaLinkedin, FaEnvelope, FaTelegram, FaPhone } from 'react-icons/fa';
import { SiPhp, SiMysql, SiPostgresql, SiReact, SiRedis, SiLaravel, SiRabbitmq } from 'react-icons/si';
import { FaJs } from 'react-icons/fa';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import MatrixBackground from './components/MatrixBackground';
import Contact from './components/Contact';
import Modal from './components/Modal';
import YiiIcon from './components/YiiIcon';
import { useTheme } from './context/ThemeContext';
import { FaMoon, FaSun } from 'react-icons/fa';

function App() {
  const { isDarkMode, toggleTheme } = useTheme();
  const [isContactModalOpen, setIsContactModalOpen] = useState(false);
  const aboutRef = useRef(null);
  const skillsRef = useRef(null);
  const projectsRef = useRef(null);

  const aboutInView = useInView(aboutRef, { once: true });
  const skillsInView = useInView(skillsRef, { once: true });
  const projectsInView = useInView(projectsRef, { once: true });

  const skills = [
    { name: 'PHP', icon: <SiPhp className={`text-5xl ${isDarkMode ? 'text-primary group-hover:text-dark' : 'text-green-600 group-hover:text-green-700'}`} /> },
    { name: 'Laravel', icon: <SiLaravel className={`text-5xl ${isDarkMode ? 'text-primary group-hover:text-dark' : 'text-green-600 group-hover:text-green-700'}`} /> },
    { name: 'Yii2', icon: <YiiIcon className={`text-5xl ${isDarkMode ? 'text-primary group-hover:text-dark' : 'text-green-600 group-hover:text-green-700'}`} /> },
    { name: 'React', icon: <SiReact className={`text-5xl ${isDarkMode ? 'text-primary group-hover:text-dark' : 'text-green-600 group-hover:text-green-700'}`} /> },
    { name: 'MySQL', icon: <SiMysql className={`text-5xl ${isDarkMode ? 'text-primary group-hover:text-dark' : 'text-green-600 group-hover:text-green-700'}`} /> },
    { name: 'PostgreSQL', icon: <SiPostgresql className={`text-5xl ${isDarkMode ? 'text-primary group-hover:text-dark' : 'text-green-600 group-hover:text-green-700'}`} /> },
    { name: 'Redis', icon: <SiRedis className={`text-5xl ${isDarkMode ? 'text-primary group-hover:text-dark' : 'text-green-600 group-hover:text-green-700'}`} /> },
    { name: 'RabbitMQ', icon: <SiRabbitmq className={`text-5xl ${isDarkMode ? 'text-primary group-hover:text-dark' : 'text-green-600 group-hover:text-green-700'}`} /> },
    { name: 'JavaScript', icon: <FaJs className={`text-5xl ${isDarkMode ? 'text-primary group-hover:text-dark' : 'text-green-600 group-hover:text-green-700'}`} /> },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    arrows: true,
    cssEase: "cubic-bezier(0.87, 0.03, 0.41, 0.9)",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const smoothAnimation = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const staggerContainer = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const fadeInUp = {
    hidden: {
      opacity: 0,
      y: 20,
    },
    visible: {
      opacity: 1,
      y: 0,
      transition: {
        duration: 0.6,
        ease: [0.6, -0.05, 0.01, 0.99],
      },
    },
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      });
    }
  };

  return (
    <div className={`min-h-screen relative ${isDarkMode ? 'bg-[#0a192f]' : 'bg-gray-50'}`}>
      <MatrixBackground />
      
      {/* Navigation */}
      <motion.nav
        initial="hidden"
        animate="visible"
        variants={smoothAnimation}
        className={`${isDarkMode ? 'bg-dark-overlay/80' : 'bg-white/80'} backdrop-blur-md shadow-lg sticky top-0 z-50`}
      >
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between h-16 items-center">
            <h1 className={`text-xl font-bold ${isDarkMode ? 'text-primary hover:text-secondary' : 'text-green-600 hover:text-green-700'} transition-colors duration-300`}>
              Doston Usmonov
            </h1>
            <div className="flex items-center space-x-8">
              <motion.a
                onClick={() => scrollToSection('about')}
                className={`nav-link ${isDarkMode ? 'text-light hover:text-primary' : 'text-gray-700 hover:text-green-600'} transition-colors duration-300 text-lg cursor-pointer`}
              >
                About
              </motion.a>
              <motion.a
                onClick={() => scrollToSection('skills')}
                className={`nav-link ${isDarkMode ? 'text-light hover:text-primary' : 'text-gray-700 hover:text-green-600'} transition-colors duration-300 text-lg cursor-pointer`}
              >
                Skills
              </motion.a>
              <motion.a
                onClick={() => scrollToSection('contact')}
                className={`nav-link ${isDarkMode ? 'text-light hover:text-primary' : 'text-gray-700 hover:text-green-600'} transition-colors duration-300 text-lg cursor-pointer`}
              >
                Contact
              </motion.a>
              <button
                onClick={toggleTheme}
                className={`p-2 rounded-full ${
                  isDarkMode 
                    ? 'bg-gray-800 text-yellow-400 hover:bg-gray-700' 
                    : 'bg-gray-100 text-gray-700 hover:bg-gray-200'
                } transition-all duration-200`}
                aria-label="Toggle theme"
              >
                {isDarkMode ? <FaSun className="w-5 h-5" /> : <FaMoon className="w-5 h-5" />}
              </button>
            </div>
          </div>
        </div>
      </motion.nav>

      {/* Hero Section */}
      <motion.section
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={staggerContainer}
        className="relative py-20"
      >
        <div className="section-container text-center">
          <motion.div
            variants={fadeInUp}
            className={`bg-${isDarkMode ? 'dark-overlay' : 'white'} backdrop-blur-md rounded-lg p-8 md:p-12`}
          >
            <h1 className={`text-4xl md:text-6xl font-bold mb-6 ${isDarkMode ? 'text-light' : 'text-gray-900'}`}>
              Hi, I'm Doston Usmonov
            </h1>
            <p className={`text-xl md:text-2xl mb-8 ${isDarkMode ? 'text-light/90' : 'text-gray-700'}`}>
              Full Stack Developer with expertise in PHP frameworks and modern JavaScript
            </p>
            <motion.a
              href="#contact"
              className={`inline-block ${isDarkMode ? 'bg-primary text-dark' : 'bg-green-600 text-white'} px-8 py-3 rounded-full font-medium hover:${isDarkMode ? 'bg-secondary' : 'bg-green-700'} transition-colors duration-300 shadow-lg`}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
            >
              Get in Touch
            </motion.a>
          </motion.div>
        </div>
      </motion.section>

      {/* About Section */}
      <motion.section
        ref={aboutRef}
        id="about"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={staggerContainer}
        className="py-20"
      >
        <div className="section-container">
          <motion.div
            variants={fadeInUp}
            className={`bg-${isDarkMode ? 'dark-overlay' : 'white'} backdrop-blur-md rounded-lg p-8`}
          >
            <h2 className={`text-3xl font-bold mb-6 ${isDarkMode ? 'text-primary' : 'text-green-600'}`}>About Me</h2>
            <p className={`text-lg mb-4 ${isDarkMode ? 'text-light/90' : 'text-gray-700'}`}>
              I am a passionate software developer with expertise in PHP, Yii2, Laravel, MySQL, PostgreSQL, 
              React, RabbitMQ, Redis, and JavaScript. I enjoy building robust and scalable applications 
              that solve real-world problems.
            </p>
            <p className={`text-lg ${isDarkMode ? 'text-light/90' : 'text-gray-700'}`}>
              When I'm not coding, you can find me playing volleyball, football, or chess. I also enjoy 
              reading books and continuously learning about new technologies and programming concepts.
            </p>
          </motion.div>
        </div>
      </motion.section>

      {/* Skills Section */}
      <motion.section
        ref={skillsRef}
        id="skills"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={staggerContainer}
        className="py-20"
      >
        <div className="section-container">
          <motion.div
            variants={fadeInUp}
            className={`bg-${isDarkMode ? 'dark-overlay' : 'white'} backdrop-blur-md rounded-lg p-8`}
          >
            <h2 className={`text-3xl font-bold mb-12 ${isDarkMode ? 'text-primary' : 'text-green-600'} text-center`}>My Skills</h2>
            <div className="skill-slider-container px-4">
              <Slider {...sliderSettings} className="skills-slider">
                {skills.map((skill, index) => (
                  <div key={skill.name} className="px-2">
                    <motion.div
                      initial={{ opacity: 0, y: 20 }}
                      animate={{ opacity: 1, y: 0 }}
                      transition={{ delay: index * 0.1 }}
                      className={`group skill-card ${
                        isDarkMode 
                          ? 'bg-dark hover:bg-primary' 
                          : 'bg-white hover:bg-green-600'
                      } transition-all duration-300 p-8 rounded-xl flex flex-col items-center shadow-lg border border-${
                        isDarkMode ? 'primary/20' : 'green-600/20'
                      } h-[200px] justify-center transform hover:scale-105`}
                    >
                      {skill.icon}
                      <h3 className={`mt-6 text-xl font-medium ${
                        isDarkMode 
                          ? 'text-primary group-hover:text-dark' 
                          : 'text-green-600 group-hover:text-white'
                      } transition-colors duration-300`}>
                        {skill.name}
                      </h3>
                    </motion.div>
                  </div>
                ))}
              </Slider>
            </div>
          </motion.div>
        </div>
      </motion.section>

      {/* Contact Section */}
      <motion.section
        ref={projectsRef}
        id="contact"
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={staggerContainer}
        className="py-20"
      >
        <div className="section-container">
          <motion.div
            variants={fadeInUp}
            className={`bg-${isDarkMode ? 'dark-overlay' : 'white'} backdrop-blur-md rounded-lg p-8 md:p-12 shadow-lg`}
          >
            <h2 className={`text-3xl font-bold text-center mb-12 ${isDarkMode ? 'text-light' : 'text-gray-900'}`}>Get in Touch</h2>

            {/* Contact Button */}
            <div className="flex justify-center mb-8">
              <motion.button
                onClick={() => setIsContactModalOpen(true)}
                className={`px-6 py-3 ${isDarkMode ? 'bg-green-600 text-white' : 'bg-green-600 text-white'} rounded-lg hover:${isDarkMode ? 'bg-green-700' : 'bg-green-700'} transition-colors duration-200`}
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
              >
                Xabar Yuborish
              </motion.button>
            </div>

            {/* Social Links */}
            <div className="flex justify-center space-x-6">
              <motion.a
                href="https://github.com/doston-usmonov"
                target="_blank"
                rel="noopener noreferrer"
                className={`text-3xl ${isDarkMode ? 'text-light hover:text-primary' : 'text-gray-700 hover:text-green-600'} transition-colors duration-300`}
                title="GitHub"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              >
                <FaGithub />
              </motion.a>
              <motion.a
                href="https://linkedin.com/in/dostonusmonov"
                target="_blank"
                rel="noopener noreferrer"
                className={`text-3xl ${isDarkMode ? 'text-light hover:text-primary' : 'text-gray-700 hover:text-green-600'} transition-colors duration-300`}
                title="LinkedIn"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              >
                <FaLinkedin />
              </motion.a>
              <motion.a
                href="https://t.me/DostonUsmonov"
                target="_blank"
                rel="noopener noreferrer"
                className={`text-3xl ${isDarkMode ? 'text-light hover:text-primary' : 'text-gray-700 hover:text-green-600'} transition-colors duration-300`}
                title="Telegram"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              >
                <FaTelegram />
              </motion.a>
              <motion.a
                href="mailto:doston1533@gmail.com"
                className={`text-3xl ${isDarkMode ? 'text-light hover:text-primary' : 'text-gray-700 hover:text-green-600'} transition-colors duration-300`}
                title="Email"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              >
                <FaEnvelope />
              </motion.a>
              <motion.a
                href="tel:+998944322822"
                className={`text-3xl ${isDarkMode ? 'text-light hover:text-primary' : 'text-gray-700 hover:text-green-600'} transition-colors duration-300`}
                title="Phone"
                whileHover={{ scale: 1.2 }}
                whileTap={{ scale: 0.9 }}
              >
                <FaPhone />
              </motion.a>
            </div>
            <div className="mt-8 text-center">
              <p className={`text-${isDarkMode ? 'light' : 'gray-700'}`}>Phone: <a href="tel:+998944322822" className={`hover:${isDarkMode ? 'text-primary' : 'text-green-600'} transition-colors duration-300`}>+998 94 432 28 22</a></p>
            </div>
          </motion.div>
        </div>
      </motion.section>

      {/* Contact Modal */}
      <Modal isOpen={isContactModalOpen} onClose={() => setIsContactModalOpen(false)}>
        <Contact onClose={() => setIsContactModalOpen(false)} />
      </Modal>

      {/* Footer */}
      <motion.footer
        initial="hidden"
        whileInView="visible"
        viewport={{ once: true }}
        variants={smoothAnimation}
        className={`bg-${isDarkMode ? 'dark-overlay/80' : 'white/80'} backdrop-blur-md text-${isDarkMode ? 'light' : 'gray-700'} py-8 mt-16`}
      >
        <div className="section-container text-center">
          <p>&copy; {new Date().getFullYear()} Doston Usmonov. All rights reserved.</p>
        </div>
      </motion.footer>
    </div>
  );
}

export default App;
