import React from 'react';

const YiiIcon = ({ className }) => {
  return (
    <svg 
      viewBox="0 0 256 256" 
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      width="1em"
      height="1em"
    >
      <g fill="currentColor">
        <path d="M128 0C57.308 0 0 57.308 0 128s57.308 128 128 128 128-57.308 128-128S198.692 0 128 0zM64 96h32v96H64V96zm96 96h-32v-64h32v64zm32-96v96h-32V96h32z"/>
      </g>
    </svg>
  );
};

export default YiiIcon;
