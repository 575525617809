import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import ReCAPTCHA from "react-google-recaptcha";
import { useTheme } from '../context/ThemeContext';

const Contact = ({ onClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });
  const [status, setStatus] = useState('');
  const [loading, setLoading] = useState(false);
  const recaptchaRef = useRef();
  const { isDarkMode } = useTheme();

  // Modal ochilganda status ni tozalash
  useEffect(() => {
    setStatus('');
    setFormData({ name: '', email: '', message: '' });
    if (recaptchaRef.current) {
      recaptchaRef.current.reset();
    }
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
    // Input o'zgarganda status ni tozalash
    setStatus('');
  };

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setStatus('');

    // Input validatsiya
    if (!validateEmail(formData.email)) {
      setStatus('invalid-email');
      setLoading(false);
      return;
    }

    if (formData.message.length < 10) {
      setStatus('short-message');
      setLoading(false);
      return;
    }

    try {
      // reCAPTCHA tokenni olish
      const token = await recaptchaRef.current.getValue();
      if (!token) {
        setStatus('recaptcha');
        setLoading(false);
        return;
      }
      
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          recaptchaToken: token
        }),
      });

      const data = await response.json();
      
      if (response.ok) {
        setStatus('success');
        setFormData({ name: '', email: '', message: '' });
        recaptchaRef.current.reset();
        // Modal ni yopish
        setTimeout(() => {
          onClose();
        }, 2000);
      } else {
        setStatus(data.error || 'error');
      }
    } catch (error) {
      console.error('Xatolik:', error);
      setStatus('error');
    } finally {
      setLoading(false);
    }
  };

  const getErrorMessage = (error) => {
    switch (error) {
      case 'success':
        return { text: 'Xabaringiz muvaffaqiyatli yuborildi!', color: 'text-green-400' };
      case 'invalid-email':
        return { text: "Noto'g'ri email format.", color: 'text-yellow-400' };
      case 'short-message':
        return { text: "Xabar juda qisqa. Kamida 10 ta belgi bo'lishi kerak.", color: 'text-yellow-400' };
      case 'rate-limit':
        return { text: "Ko'p so'rov yubordingiz. Iltimos biroz kutib turing.", color: 'text-red-400' };
      case 'recaptcha':
        return { text: "reCAPTCHA tekshiruvidan o'ting.", color: 'text-yellow-400' };
      case 'server-error':
        return { text: 'Server xatosi yuz berdi. Iltimos keyinroq urinib ko\'ring.', color: 'text-red-400' };
      case 'error':
        return { text: 'Server bilan bog\'lanishda xatolik yuz berdi.', color: 'text-red-400' };
      default:
        return null;
    }
  };

  const statusMessage = getErrorMessage(status);

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      className={`p-6 ${isDarkMode ? 'bg-gray-800/50' : 'bg-white/50'} backdrop-blur-md rounded-lg shadow-xl`}
    >
      <h2 className={`text-2xl font-bold ${isDarkMode ? 'text-green-500' : 'text-green-600'} mb-6`}>Bog'lanish</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label htmlFor="name" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            Ismingiz
          </label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            required
            minLength={2}
            className={`mt-1 block w-full rounded-md ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 text-white' 
                : 'bg-white border-gray-300 text-gray-900'
            } shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50`}
          />
        </div>
        <div>
          <label htmlFor="email" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            Email
          </label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            required
            className={`mt-1 block w-full rounded-md ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 text-white' 
                : 'bg-white border-gray-300 text-gray-900'
            } shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50`}
          />
        </div>
        <div>
          <label htmlFor="message" className={`block text-sm font-medium ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
            Xabar
          </label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            required
            minLength={10}
            rows="4"
            className={`mt-1 block w-full rounded-md ${
              isDarkMode 
                ? 'bg-gray-700 border-gray-600 text-white' 
                : 'bg-white border-gray-300 text-gray-900'
            } shadow-sm focus:border-green-500 focus:ring focus:ring-green-500 focus:ring-opacity-50`}
          />
        </div>
        <div className="flex justify-center">
          <ReCAPTCHA
            ref={recaptchaRef}
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            theme={isDarkMode ? "dark" : "light"}
          />
        </div>
        <button
          type="submit"
          disabled={loading}
          className={`w-full py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white ${
            isDarkMode ? 'bg-green-600 hover:bg-green-700' : 'bg-green-500 hover:bg-green-600'
          } focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500 transition-colors duration-200 ${
            loading ? 'opacity-50 cursor-not-allowed' : ''
          }`}
        >
          {loading ? (
            <span className="flex items-center justify-center">
              <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
              </svg>
              Yuborilmoqda...
            </span>
          ) : (
            'Yuborish'
          )}
        </button>
      </form>
      {statusMessage && (
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className={`mt-4 text-sm ${statusMessage.color}`}
        >
          {statusMessage.text}
        </motion.p>
      )}
    </motion.div>
  );
};

export default Contact;
